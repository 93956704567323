<template>
	<div class="page-container content">
		<div class="subtitle has-text-weight-semibold pageTitle">Subscriptions on server</div>

		<table class="table is-bordered is-narrow is-hoverable">
			<thead>
				<tr style="background-color: #eeeeee">
					<th class="tableHead">User id</th>
					<th class="tableHead">OS info</th>
					<th class="tableHead">Send Before Days</th>
					<th class="tableHead">Time offset</th>
					<th class="tableHead">On local time</th>
					<th class="tableHead">Last sent UTC</th>
					<th></th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="subscription, index in subscriptions" :style="{'font-weight': isTheDeviceSubscription(subscription.endpoint) ? 'bold' : 'normal'}">
					<td class="tableCell">{{ subscription.userId }}</td>
					<td class="tableCell">{{ subscription.osInfo.platform }}, Browser: {{ subscription.osInfo.browserName }}</td>
					<td class="tableCell">{{ subscription.sendBeforeDays }}</td>
					<td class="tableCell">{{ subscription.timeOffset }}</td>
					<td class="tableCell">{{ subscription.localTimeToSendNotification }}</td>
					<td class="tableCell">{{ subscription.lastSentUTC }}</td>
					<td style="white-space: nowrap; width: 1px;">
						{{ isTheDeviceSubscription(subscription.endpoint) ? "This is the current client subscription." : "" }}
					</td>
					<td class="tableCell">
						<div class="actionImagesContainer">
							<button class="button" @click="sendTestNotification(subscription.id)">Send test notification</button>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { globalSettings } from "@/mixins/globalSettings";
import { mapState, mapMutations, Store } from 'vuex'

export default {
	name: 'ServerSubscriptions',
	mixins: [globalSettings],
	components: {
	},
	data: function() {
		return {
			subscriptions: [],
			clientSubscription: null
		}
	},
	created: function() {
		this.getSubscription()
		.then(subs => {
			this.clientSubscription = subs;
		});
		this.getServerSubscriptions();
	},
	computed: {
		...mapState([
			"goptions"
		])
	},
	methods: {
		isTheDeviceSubscription(endpoint) {
			if (this.clientSubscription == null) {
				return false;
			}
			return endpoint == this.clientSubscription.endpoint;
		},
		sendTestNotification(subscriptionId) {
			this.showWait(true);

			this.$ky(this.apiUrl + "/SendTestComplexNotification2", {
				method: "GET",
				searchParams: {
					subscriptionId: subscriptionId
				},
				cache: "no-cache",
				timeout: 3000,
				retry: 1
				})
				.json()
				.then(responseJson => {
					this.subscriptionssuccess = responseJson.success;
					this.showWait(false);
				})
				.catch(error => {
					this.showWait(false);
					alert("Error: " + error.message);
				});
		},
		getServerSubscriptions() {
			this.showWait(true);

			this.$ky(this.apiUrl + "/GetAllSubscriptions", {
				method: "GET",
				searchParams: {
				},
				cache: "no-cache",
				timeout: 3000,
				retry: 1
				})
				.json()
				.then(responseJson => {
					// Deserialize the os-related field, returned from server as JSON formatted string.
					for (let i = 0; i < responseJson.subscriptions.length; i++) {
						responseJson.subscriptions[i].osInfo = JSON.parse(responseJson.subscriptions[i].osInfo);
					}
					
					this.subscriptions = responseJson.subscriptions;
					this.showWait(false);
				})
				.catch(error => {
					this.showWait(false);
					alert("Error: " + error.message);
				});
		}
	}
}
</script>

<style>
.tableHead {
	width: 1px;
}
.tableCell {
	white-space: nowrap;
}
</style>