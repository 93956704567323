<template>
	<div class="box is-size-4 has-text-centered">App version: {{ version }}</div>
	<div class="box is-size-4 has-text-centered">User: {{ goptions.user.userName }}</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'
import packageJson from "../../package.json";

export default {
	name: "about",
	data: function() {
		return {
		}
	},
	computed: {
		...mapState([
			"goptions"
		]),
		version: function() {
			return packageJson.version;
		}
	}
}
</script>